/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-07-15 10:43:37
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-28 22:53:13
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listDealerPreOrdersNum = params => axios({
    url: '/api/base/quota/dealerPreOrdersNum/firmListData',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addDealerPreOrdersNum = params => axios({
    url: '/api/base/quota/dealerPreOrdersNum/dealerUpdateData',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editDealerPreOrdersNum = params => axios({
    url: '/api/base/quota/dealerPreOrdersNum/dealerUpdateData',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delDealerPreOrdersNum = params => axios({
    url:'/api/dealer_pre_orders_num/system/dealerPreOrdersNum/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdDealerPreOrdersNum = params => axios({
  url: '/api/base/quota/dealerPreOrdersNum/selectById/' + params,
  method: 'get',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})
